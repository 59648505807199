import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"

import Logo from "../../images/am-icon.png"

const Container = styled.header`
  /* background: ${v.darkPurple};
  padding: ${v.baseline()} 0;
  ${v.grid};

  h1 {
    grid-column: main;
  }

  a {
    color: white;
    text-decoration: none;
    ${v.heading.c};
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: block;
      width: ${v.baseline(3)};
      height: ${v.baseline(3)};
      background: url(${Logo}) no-repeat center;
      background-size: contain;
      margin-right: ${v.baseline(0.5)};
    }
  } */
`

const Header = ({ siteTitle }) => <Container></Container>

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
