/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styled, { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import * as v from "../variables"

import Header from "./layoutHeader"

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    ${v.reset}
  }
`

const Main = styled.main`
  min-height: ${"calc(100vh - " + v.baseline(11) + ")"};
`

const Footer = styled.footer`
  ${v.grid};
  padding: ${v.baseline(2)} 0;
  p {
    grid-column: main;
    ${v.text.c};
    color: rgb(0 0 0 / 0.4);
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />

      <Header siteTitle={data.site.siteMetadata.title} />

      <Main>{children}</Main>
      {/* <Footer>
        <p>© {new Date().getFullYear()} Armstrong Medical</p>
      </Footer> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
