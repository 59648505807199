import { css } from "styled-components"

// Color
export const purple = "#75598F"
export const darkPurple = "#1F1932"

// Typography

export const sans = "effra-1, effra-2, arial, sans-serif; text-indent: -0.17ex"

export const baseline = (multiplier = 1) => `${12 * multiplier}px`

export const heading = {
  a: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 54px;
      line-height: ${baseline(5)};
      font-weight: 300;
      `,
  b: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 36px;
      line-height: ${baseline(4)};
      font-weight: 300;
      `,
  c: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 24px;
      line-height: ${baseline(2.5)};
      font-weight: 300;
      `,
  d: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.11em;
      line-height: ${baseline(2)};
      font-weight: 400;
      `,
  e: `
      font-family: ${sans};
      font-feature-settings: "pnum";
      font-size: 16px;
      line-height: ${baseline(2)};
      font-weight: 400;
      `,
  f: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.11em;
      line-height: ${baseline(2)};
      font-weight: 400;
      `,
}

export const text = {
  a: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 18px;
      line-height: ${baseline(2)};
      font-weight: 400;
    `,
  b: `
      font-family: ${sans};
      font-feature-settings: "pnum";
      font-size: 16px;
      line-height: ${baseline(2)};
      font-weight: 400;
    `,
  c: `
      font-family: ${sans};
      font-feature-settings:  "pnum";
      font-size: 14px;
      line-height: ${baseline(2)};
      font-weight: 400;
    `,
}

//   Animation

export const speed = {
  fast: "0.2s",
  medium: "0.5s",
  slow: "1s",
}

// Layout

export const grid = css`
  display: grid;
  grid-template-columns: [fullbleed-start] 0px [full-start outdent-start main-start] 1fr [indent-start] 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr [indent-end] 1fr [full-end outdent-end main-end] 0px [fullbleed-end];
  grid-gap: ${baseline(2)};

  @media all and (min-width: 768px) {
    grid-template-columns: [fullbleed-start] ${baseline()} [full-start outdent-start] 1fr 1fr [main-start] 1fr [indent-start] 1fr 1fr 1fr 1fr 1fr 1fr [indent-end] 1fr [main-end] 1fr 1fr [full-end outdent-end] ${baseline()} [fullbleed-end];
  }

  @media all and (min-width: 1024px) {
    grid-template-columns: [fullbleed-start] ${baseline(2)} [full-start] 1fr [outdent-start main-start] 1fr 1fr [indent-start] 1fr 1fr 1fr 1fr 1fr 1fr [indent-end] 1fr 1fr [main-end outdent-end] 1fr [full-end] ${baseline(
        2
      )} [fullbleed-end];
    grid-gap: ${baseline(3)};
  }

  @media all and (min-width: 1440px) {
    grid-template-columns: [fullbleed-start] ${baseline(2)} [full-start] 1fr 1fr [outdent-start] 1fr [main-start] 1fr [indent-start] 1fr 1fr 1fr 1fr [indent-end] 1fr [main-end] 1fr [outdent-end] 1fr 1fr [full-end] ${baseline(
        2
      )} [fullbleed-end];
    grid-gap: ${baseline(4)};
  }
`

// Reset

export const reset = css`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  opacity: 1;
  color: inherit;
  list-style-type: none;
  background: none;
  text-decoration: none;
  font-family: ${sans};
`
